const config = {
  dev: process.env.LOCAL,
  stage: process.env.STAGE,
  prod: process.env.PRODUCTION,
  endpoint: {
    aiSearch: "/aiengine/message",
    getSearchHistory: "/aiengine/session/history",
    welcomeMsg: "/aiengine/message",
    searchDocument: "/aiengine/knowledgebase/documents",
    resetChatHistory: "/aiengine/session/reset",
    giveFeedback: "/aiengine/feedback",
  },
};
export default config;
