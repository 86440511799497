import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import Error from "../Error/Error";
import { msgError, msgLoading, generateUniqueId } from "../../libs/constant";
import reset from "../../images/resetSearchFiles.svg";
import styles from "./Suggestions.module.css";

function Suggestions(props) {
  const welcomeMsg = useSelector((state) => {
    return state.welcomeMsg;
  });
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingText}>
          Ask Genie Sample Questions and Suggestions
        </div>
        <div className={styles.closeBtn} onClick={props.closeSuggestionsBox}>
          <span>X</span>
          {/* <img src={reset} alt="Close" /> */}
        </div>
      </div>
      <div className={styles.suggestions}>
        {welcomeMsg.isWelcomeMsgLoading ? (
          <Loader text={msgLoading} />
        ) : welcomeMsg.isWelcomeMsgError ? (
          <Error text={msgError} />
        ) : welcomeMsg.welcomeMsgData &&
          Object.keys(welcomeMsg.welcomeMsgData).length &&
          welcomeMsg.welcomeMsgData.message[0] &&
          welcomeMsg.welcomeMsgData.message[0].options ? (
          welcomeMsg.welcomeMsgData.message[0].options.map((item) => {
            return (
              <div
                key={generateUniqueId()}
                className={styles.suggestion}
                onClick={() => {
                  props.searchUserInput(item.label);
                }}
              >
                {item.label}
              </div>
            );
          })
        ) : (
          <div className={styles.noSuggestion}>No suggestions found</div>
        )}
      </div>
    </div>
  );
}

export default Suggestions;
