import moment from "moment";

export const getEnv = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return "development";
  } else {
    return "production";
  }
};

export const getApiBaseURL = () => {
  return urls && urls.apiLink;
};
export const getMinioBaseURL = () => {
  return urls && urls.genAIminioLink;
};

export const regexs = {
  jsonString: /\{\"sources\"\:(.*)\}/g,
  pdfURL1: /(\[(.*?)\.pdf\])(\(((https|http)\:\/\/minio\.(.*?).pdf)\))?/g,
  pdfURL2: /\(((https|http)\:\/\/minio\.(.*?)\.pdf)\)/g,
};
export const msgError = "Something went wrong";
export const msgLoading = "Loading...";
export const msgDataNotFound = "Data Not Found";

export const chatType = {
  bot: "bot",
  user: "user",
};
export const getFormattedDateTime = (date) => {
  return date ? moment(date).format("DD-MMM-YYYY hh:mm A") : date;
};

export const convertUTCtolocal = (date) => {
  return date ? moment.utc(date).local() : date;
};

export const isTextHasValue = (text) => {
  return !(
    text === "undefined" ||
    text === undefined ||
    text === "null" ||
    text === null ||
    text === ""
  );
};

export const pauseBrowser = (millis) => {
  const date = Date.now();
  let curDate = null;
  do {
    curDate = Date.now();
  } while (curDate - date < millis);
};
export const generateUniqueId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return Math.random().toString(36).substr(2, 9);
};
