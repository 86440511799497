import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import styles from "./ChatWindow.module.css";
import ChatItem from "../components/ChatItem/ChatItem";
import ChatUserInputBox from "../components/ChatUserInputBox/ChatUserInputBox";
import {
  msgError,
  msgLoading,
  isTextHasValue,
  chatType,
} from "../libs/constant";
import {
  resetChatHistory,
  showChatHistoryLoading,
  getChatHistoryData,
  searchAItext,
  showAIsearchLoading,
  feedback,
  feedbackReset,
  welcomeMsgReset,
  getWelcomMsg,
  welcomeMsgLoading,
  // feedbackLoading,
} from "../actions/chatAction";

import { chatHistorySuccess } from "../actions/actionCreator";
import Loader from "../components/Loader/Loader";
import Error from "../components/Error/Error";
import ChatTimeLine from "../components/ChatTimeLine/ChatTimeLine";
import Disclaimer from "../components/Disclaimer/Disclaimer";

const welcomeOptions = [
  {
    label: "SUGGESTIONS",
    value: "SUGGESTIONS",
    type: "",
  },
  {
    label: "Credit Card Limit",
    value: "",
    type: "",
  },
  {
    label: "Insta Service",
    value: "",
    type: "",
  },
  {
    label: "Home Loan",
    value: "",
    type: "",
  },
];

function ChatWindow(props) {
  const [historyData, setHistoryData] = useState(null);
  const [userInput, setUserInput] = useState(null);
  const [onePage, setOnePage] = useState(1);
  const [currentFeedbackmsgID, setCurrentFeedbackmsgID] = useState(null);
  const [disclaimer, setDisclaimer] = useState(true);
  const { userName, userADID, channel, paramSearchText } = props;
  const chatBoxRef = useRef(null);
  const dispatch = useDispatch();
  const chatHistory = useSelector((state) => {
    return state.chatHistory;
  });
  const userInputSearch = useSelector((state) => {
    return state.aiSearch;
  });
  const userFeedback = useSelector((state) => {
    return state.feedback;
  });
  const welcomeMsg = useSelector((state) => {
    return state.welcomeMsg;
  });
  useEffect(() => {
    dispatch(feedbackReset());
    loadWelcomeMsg();
    // scrollBottom(100);
  }, []);
  useEffect(() => {
    setHistoryData(chatHistory);
  }, [chatHistory]);
  useEffect(() => {
    if (userInputSearch && userInputSearch.aiSearchData.length) {
      pushUserChatInMainHistory(userInputSearch.aiSearchData[0].message);
    }
  }, [userInputSearch]);
  useEffect(() => {
    if (isChatHistoryAvailable() && userFeedback.feedbackSuccess) {
      let copyChatHistory = JSON.parse(JSON.stringify(historyData));
      const data1 = copyChatHistory.chatHistoryData.chatHistory.map(
        (message) => {
          if (currentFeedbackmsgID.messageId === message.messageId) {
            message.feedback = currentFeedbackmsgID.feedback;
            return message;
          } else {
            return message;
          }
        },
      );
      copyChatHistory.chatHistoryData.chatHistory = data1;
      dispatch(chatHistorySuccess(copyChatHistory.chatHistoryData));
      setCurrentFeedbackmsgID(null);
    }
  }, [userFeedback]);
  const pushUserChatInMainHistory = (message) => {
    if (isChatHistoryAvailable()) {
      let copyChatHistory = JSON.parse(JSON.stringify(historyData));

      let chatHistory0 = copyChatHistory.chatHistoryData.chatHistory;
      copyChatHistory.chatHistoryData.chatHistory = [
        ...chatHistory0,
        ...sortInputResponse(message),
      ];
      dispatch(chatHistorySuccess(copyChatHistory.chatHistoryData));
    } else {
      const data0 = {
        pageNo: 0,
        pageSize: 10,
        totalRecords: 100,
        regardingId: "",
        userName: userName,
        sessionId: userADID,
        channel: channel,
        chatHistory: sortInputResponse(message),
      };
      dispatch(chatHistorySuccess(data0));
    }
    scrollBottom(100);
  };
  const sortInputResponse = (data) => {
    const user =
      data &&
      data.filter((item) => {
        if (item.mode) {
          return item.mode.toLowerCase() === chatType.user;
        }
      });
    const bot =
      data &&
      data.filter((item) => {
        if (item.mode) {
          return item.mode.toLowerCase() === chatType.bot;
        }
      });
    return [...user, ...bot];
  };
  const scrollBottom = (duration) => {
    setTimeout(() => {
      if (chatBoxRef.current) {
        const chatBoxHeight = chatBoxRef.current.scrollHeight;
        chatBoxRef.current.scrollTop = chatBoxHeight;
      }
    }, duration);
  };
  const regenerate = (data) => {
    searchUserInput(getLinkedIDText(data.linkedMessageId));
  };
  const getLinkedIDText = (linkedMessageId) => {
    const data =
      historyData &&
      historyData.chatHistoryData &&
      historyData.chatHistoryData.chatHistory.filter((item) => {
        return item.messageId === linkedMessageId;
      });

    return data && data.length && data[0].text;
  };
  const loadHistoryData = () => {
    dispatch(resetChatHistory());
    dispatch(showChatHistoryLoading());
    dispatch(
      getChatHistoryData(
        { pageNo: 0, pageSize: 10, sessionId: userADID },
        (response) => {
          // This search is when search text is passed in url as a parameter
          if (isTextHasValue(paramSearchText)) {
            searchUserInput(paramSearchText);
          } else {
            // scrolls here becuase no data is there about to come as there is no param
            scrollBottom(1000);
          }
        },
      ),
    );
  };
  const loadWelcomeMsg = () => {
    const payLoad = {
      regardingId: "",
      userName: userName,
      sessionId: userADID,
      channel: channel,
      message: "",
    };
    dispatch(welcomeMsgReset());
    dispatch(welcomeMsgLoading());
    dispatch(
      getWelcomMsg(payLoad, () => {
        loadHistoryData();
      }),
    );
  };
  const searchUserInput = (searchText) => {
    setDisclaimer(false);
    setUserInput(searchText);
    scrollBottom(100);
    const payLoad = {
      regardingId: "",
      userName: userName,
      sessionId: userADID,
      channel: channel,
      message: searchText,
    };
    dispatch(showAIsearchLoading());
    dispatch(searchAItext(payLoad));
  };
  const sendFeedback = (feedback0, messageId) => {
    setCurrentFeedbackmsgID({
      feedback: feedback0,
      messageId: messageId,
    });
    const payLoad = {
      sessionId: userADID,
      messageId,
      feedBack: feedback0,
    };
    dispatch(feedback(payLoad));
  };
  const onScroll = () => {
    if (chatBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("At bottom");
      } else if (scrollTop === 0) {
        console.log("At top");
      }
    }
  };
  const isChatHistoryAvailable = () => {
    return (
      chatHistory &&
      chatHistory.chatHistoryData &&
      chatHistory.chatHistoryData.chatHistory &&
      chatHistory.chatHistoryData.chatHistory.length
    );
  };
  const onInputShowTextAndBotLoader = () => {
    return (
      <>
        <div className={styles.chatItem}>
          <ChatTimeLine dateTime={new Date()} />
          <ChatItem data={{ mode: "USER", text: userInput }} />
        </div>
        <div className={styles.chatItem}>
          {/* <ChatTimeLine dateTime={item.createdOn} /> */}
          <ChatItem data={{ mode: "boat" }} isPreloader={true} />
        </div>
      </>
    );
  };
  return (
    <div className={styles.mainContainer}>
      {disclaimer ? (
        <div className={styles.disclaimer}>
          <Disclaimer
            searchUserInput={searchUserInput}
            setDisclaimer={setDisclaimer}
          />
        </div>
      ) : null}
      {!disclaimer ? (
        <div className={styles.chatBox} ref={chatBoxRef} onScroll={onScroll}>
          {welcomeMsg.isWelcomeMsgLoading ? (
            <Loader text={msgLoading} />
          ) : welcomeMsg.isWelcomeMsgError ? (
            <Error text={msgError} />
          ) : welcomeMsg.welcomeMsgData &&
            Object.keys(welcomeMsg.welcomeMsgData).length &&
            welcomeMsg.welcomeMsgData.message[0] ? (
            <ChatItem
              data={welcomeMsg.welcomeMsgData.message[0]}
              sendFeedback={() => {}}
              searchUserInput={() => {}}
              regenerate={() => {}}
              tool={true}
            />
          ) : null}

          {chatHistory && chatHistory.isChatHistoryLoading ? (
            <Loader text={msgLoading} />
          ) : chatHistory && chatHistory.isChatHistoryError ? (
            <Error text={msgError} />
          ) : isChatHistoryAvailable() ? (
            chatHistory &&
            chatHistory.chatHistoryData &&
            chatHistory.chatHistoryData.chatHistory &&
            chatHistory.chatHistoryData.chatHistory.map((item) => {
              return (
                <div className={styles.chatItem}>
                  <ChatTimeLine dateTime={item.createdOn} />
                  <ChatItem
                    data={item}
                    sendFeedback={sendFeedback}
                    searchUserInput={searchUserInput}
                    regenerate={regenerate}
                  />
                </div>
              );
              // });
            })
          ) : null}
          {userInputSearch.isAIsearchLoading
            ? onInputShowTextAndBotLoader()
            : null}
        </div>
      ) : null}

      <div className={styles.chatUserInput}>
        <ChatUserInputBox
          searchAItext={searchUserInput}
          disableInput={
            welcomeMsg.isWelcomeMsgLoading ||
            (chatHistory && chatHistory.isChatHistoryLoading)
          }
        />
      </div>
    </div>
  );
}

export default ChatWindow;
