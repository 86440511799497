import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import styles from "./ChatUserInputBox.module.css";
import classNames from "classnames";
import micIcon from "../../images/mic_small.svg";
import micStop from "../../images/mic_stop.svg";
import sendActive from "../../images/send2.svg";
import sendDisabled from "../../images/send2Inactive.svg";
import youIcon from "../../images/You.svg";

function ChatUserInputBox(props) {
  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    setUserInput(transcript);
  }, [transcript]);

  useEffect(() => {
    if (listening && !userInput) {
      resetTranscript(); // Clear the previous transcript
    }
  }, [userInput]);

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      if (!listening) {
        handleSubmit();
      }
    }
  };
  const handleSubmit = () => {
    if (userInput) {
      props.searchAItext(userInput);
      setUserInput("");
    }
  };
  const startSpeechTotext = () => {
    resetTranscript(); // Clear the previous transcript
    SpeechRecognition.startListening({ continuous: true });
  };
  const stopSpeechTotext = () => {
    SpeechRecognition.stopListening();
  };
  const submitInput = () => {
    if (userInput) {
      props.searchAItext(userInput);
      setUserInput("");
      stopSpeechTotext();
    } else {
      stopSpeechTotext();
    }
  };
  return (
    <div className={styles.chatUserInputBox}>
      <div className={classNames(styles.emojiS)}>
        <img src={youIcon} alt="image" />
      </div>
      <div className={classNames(styles.chatUserInputItem)}>
        <input
          type="text"
          placeholder={
            props.disableInput
              ? null
              : listening
                ? "Listening..."
                : "Start typing here ..."
          }
          value={userInput}
          onChange={(e) => {
            setUserInput(e.target.value);
          }}
          onKeyUp={(event) => {
            if (userInput) {
              handleKeyUp(event);
            }
          }}
          disabled={props.disableInput}
        />
      </div>
      <div className={classNames(styles.mic)}>
        <img
          src={listening ? micStop : micIcon}
          onClick={() => {
            if (listening) {
              submitInput();
            } else {
              startSpeechTotext();
            }
          }}
          alt="image"
        />
      </div>
      <div
        onClick={() => {
          if (userInput && !listening) {
            submitInput();
          }
        }}
        className={classNames(
          styles.submitBtnActive,
          userInput ? null : styles.removePointer,
        )}
      >
        {!listening ? (
          <img src={userInput ? sendActive : sendDisabled} alt="image" />
        ) : null}
      </div>
    </div>
  );
}

export default ChatUserInputBox;
